<template>
  <div
    class="flex flex-col items-center w-full pt-12 px-5 xl:flex-row xl:h-full xl:px-32"
  >
    <div
      class="flex flex-col items-center xl:w-2/5 xl:pl-10 xl:items-start 2xl:pr-10 2xl:pl-32"
    >
      <div class="mb-4">
        <span class="font-bold text-customGreen text-4xl">Co-Living</span>
        <span class="mx-2">x</span>
        <span class="font-bold text-customOrange text-4xl">SDGs</span>
      </div>
      <img class="w-32" src="/image/ivcBelieve.png" alt="" srcset="" />

      <div
        class="border border-gray-300 w-12 mt-16 mb-14 xl:border-transparent xl:my-10"
      />
      <div class="font-semibold text-2xl mb-1 xl:text-30 3xl:text-36">
        給青年一個住得起的家
      </div>
      <div class="text-lg mb-12 xl:mb-8 xl:mt-2 xl:text-24">
        [ 共居計畫問卷 ]
      </div>
      <div
        class="border border-gray-300 bg-white p-4 mb-14 xl:text-18 xl:text-left xl:w-3/4"
      >
        除了數據研究與生活提案，我們也想聽聽你對於共居的看法是什麼。透過這份測驗，不僅可以知道你在共居族群中屬於什麼類型的人，也可以知道屬於你的SDG共居方式，快來試試看吧！
      </div>
      <img
        src="/image/scrollToBottom.png"
        class="w-12 mb-7 xl:hidden"
        alt=""
        srcset=""
        @click="scrollToBottom"
      />
    </div>
    <!-- picture -->
    <div class="w-full flex flex-col justify-around xl:w-3/5 xl:flex-row">
      <img
        @click="goNext('Colive')"
        class="mb-7 xl:w-1/2 xl:px-2 cursor-pointer"
        src="/image/Colive.png"
        alt="Colive"
      />
      <img
        @click="goNext('SGDS')"
        class="mb-7 xl:w-1/2 xl:px-2 cursor-pointer"
        src="/image/SDGs.png"
        alt="SGDs"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["currentStep"]),
  },
  methods: {
    goNext(type) {
      this.$store.dispatch("setSurveyTypeAction", type);
      this.$store.dispatch("setStepAction", this.currentStep + 1);
    },
    scrollToBottom() {
      window.scroll({
        top: document.documentElement.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
