<template>
  <SurveyCard
    :surveyType="surveyType"
    :stepZeroImg="'/image/stepZero.png'"
    title="SDGs Actions"
    contentTitle="永續提案"
    describe="SDGs不只是遙遠的目標，其實也可以離生活很近。透過這個問卷我們想尋找有熱情也有點子的你，一起來寫下新中想到的SDGs提案！"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <SdgTag />
      <QuestionInput
        :key="updateKey + 'questionOne'"
        class="py-8"
        title="從聯合國SDGs 17項指標出發，如果可以和室友持續在社區從事一個永續行動產生社會連結，那會是什麼？"
        v-model="questionValue"
      />

      <QuestionInput
        :key="updateKey + 'name'"
        class="pb-8"
        title="請問要怎麼稱呼你？姓名或暱稱都歡迎唷！"
        v-model="questionValue_2"
      />
    </template>
  </SurveyCard>
</template>
<script>
import SurveyCard from "@/components/SurveyCard";
import SdgTag from "@/components/SdgTag";
import QuestionInput from "@/components/QuestionInput";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepZero",
  components: {
    SurveyCard,
    SdgTag,
    QuestionInput,
  },
  data() {
    return {
      questionValue: "",
      questionValue_2: "",
      updateKey: 0,
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    if (this.surveyAnswer?.sdgs_Q1)
      this.questionValue = this.surveyAnswer.sdgs_Q1;
    if (this.surveyAnswer?.name) this.questionValue_2 = this.surveyAnswer.name;
    this.updateKey++;
  },
  methods: {
    goNext() {
      if (!this.questionValue || !this.questionValue_2)
        return this.$store.dispatch("setErrorMessage", "fdsa");
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "sdgs_Q1",
          answer: this.questionValue,
        },
        {
          keyValue: "name",
          answer: this.questionValue_2,
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
