<template>
  <div class="relative w-full h-full px-4 pb-9 flex-1 xl:first:pl-0">
    <div
      class="flex flex-col justify-between h-full border rounded-xl pt-6 pb-4 px-3"
      :class="`border-customOrange`"
    >
      <div class="flex flex-col xl:mb-9">
        <h1 class="font-bold text-lg mb-4">{{ data.name }}</h1>
        <p class="text-left">{{ data.description }}</p>
      </div>
      <div class="flex flex-col">
        <p
          class="self-end text-xs text-right"
          :class="`text-customOrange`"
          v-for="text in data.subTitle"
          :key="text"
        >
          {{ text }}
        </p>
        <img :src="data.image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionCard",
  props: {
    data: {
      Type: Object,
      default: () => {},
    },
  },
};
</script>
