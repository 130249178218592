<template>
  <div class="relative w-full px-4 pt-9 pb-9 flex-1">
    <div
      class="flex flex-col justify-between items-center w-full border rounded-xl overflow-x-hidden"
      :class="`border-${color}`"
    >
      <div class="w-full pt-4 pb-2 text-2xl text-white" :class="`bg-${color}`">
        {{ animal.name }}
      </div>
      <img :src="animal.image" alt="" srcset="" />
      <div class="font-semibold text-xl mb-24">
        <span class="text-customGreen">Co-Living</span
        ><span class="px-2 text-gray-400">x</span
        ><span class="text-customOrange">SDGs</span>
      </div>
      <img
        class="w-9/12 mb-14"
        :src="animal.descriptionImage"
        alt=""
        srcset=""
      />
      <ShareDropdown />

      <div class="flex justify-between w-full">
        <div
          class="flex w-28 justify-start ml-4 mb-6 sm:w-auto sm:justify-end sm:ml-2"
          @click="goPrevious"
        >
          <img
            :src="`/image/${
              surveyType === 'Colive' ? 'green' : 'orange'
            }Previous.png`"
            alt=""
          />
        </div>

        <div
          class="flex w-20 justify-end mr-4 mb-6 sm:w-auto sm:justify-start sm:mr-2"
          @click="goNext"
        >
          <img
            :src="`/image/${
              surveyType === 'Colive' ? 'green' : 'orange'
            }Next.png`"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ShareDropdown from "@/components/ShareDropdown.vue";
export default {
  name: "AnimalResult",
  components: {
    ShareDropdown,
  },
  computed: {
    ...mapState(["surveyType", "currentStep"]),
    ...mapGetters(["color", "imageColor", "animal"]),
  },
  methods: {
    goNext() {
      this.$store.dispatch("setStepAction", this.currentStep + 1);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
