<template>
  <div
    class="flex items-center justify-center w-full min-h-full xl:min-h-[850px]"
  >
    <!-- 網頁版左邊標題 -->
    <div
      v-if="!stepZeroImg"
      class="hidden items-start justify-center w-1/2 h-full py-32 xl:flex"
    >
      <div
        class="font-normal text-18 text-black text-left ml-60 2xl:ml-72 2xl:w-1/2 xl:w-3/5"
      >
        <div class="w-min">
          <div
            class="text-36 font-bold pt-20 pb-20 whitespace-nowrap"
            :class="
              surveyType === 'Colive' ? 'text-customGreen' : 'text-customOrange'
            "
          >
            {{ webTitle }}
          </div>
          <div class="xl:text-18">
            {{ webContent }}
          </div>
        </div>
      </div>
    </div>
    <!-- 網頁版內容區塊 -->
    <div class="h-full w-full xl:w-1/2 xl:px-20">
      <!-- 內容區域 -->
      <div
        class="flex flex-col items-center justify-center relative w-full h-full px-4 pb-4"
      >
        <!-- 手機版標題 -->
        <div
          class="text-30 font-bold pb-4"
          :class="[
            surveyType === 'Colive' ? 'text-customGreen' : 'text-customOrange',
            { 'xl:hidden': !stepZeroImg },
          ]"
        >
          {{ title }}
        </div>
        <div
          class="border rounded-xl min-h-3/4 flex flex-col xl:px-7 min-h-[500px] xl:min-h-[720px]"
          :class="
            surveyType === 'Colive'
              ? 'border-customGreen'
              : 'border-customOrange'
          "
        >
          <!-- 網頁版標題 -->
          <div
            v-if="!stepZeroImg"
            class="hidden text-30 font-bold pt-6"
            :class="[
              surveyType === 'Colive'
                ? 'text-customGreen'
                : 'text-customOrange',
              { 'xl:block': !stepZeroImg },
            ]"
          >
            {{ title }}
          </div>

          <div class="flex-1">
            <!-- 內容title -->
            <div
              class="font-bold text-18 text-left pt-6 px-4"
              :class="{ 'xl:text-36': stepZeroImg }"
            >
              {{ contentTitle }}
            </div>

            <!-- 內容簡述 -->
            <div class="text-left text-12 py-2 px-4 mb-4 xl:text-18">
              {{ describe }}
            </div>

            <!-- 自定義template內容 -->
            <slot></slot>
            <!-- 按鈕區域 -->
          </div>
          <div
            class="flex w-full px-4 py-2 items-end"
            :class="hasPrevious ? 'justify-between' : 'justify-end'"
          >
            <div
              class="w-36% ml-4 cursor-pointer"
              v-if="hasPrevious"
              @click="lastStep"
            >
              <img
                :src="`/images/surveyCard/${
                  surveyType === 'Colive' ? 'green' : 'orange'
                }Previous.png`"
                alt=""
              />
            </div>

            <div
              class="w-1/4 mr-4 flex justify-end cursor-pointer"
              v-if="hasNext"
              @click="nextStep"
            >
              <img
                class="items-end"
                :src="`/images/surveyCard/${
                  surveyType === 'Colive' ? 'green' : 'orange'
                }Next.png`"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 網頁版STEP0右邊圖片 -->
    <div
      v-if="stepZeroImg"
      class="hidden items-center w-1/2 h-full p-20 xl:flex"
    >
      <img :src="stepZeroImg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SurveyCard",
  props: {
    title: {
      type: String,
      default: "Survey",
    },
    surveyType: {
      type: String,
      default: "Colive",
    },
    hasPrevious: {
      type: Boolean,
      default: true,
    },
    hasNext: {
      type: Boolean,
      default: true,
    },
    contentTitle: {
      type: String,
      default: "contentTitle",
    },
    describe: {
      type: String,
    },
    stepZeroImg: {
      type: String,
    },
  },
  data() {
    return {
      webTitle: "你適合什麼樣的共居？",
      webContent:
        "好奇自己是什麼樣性格的人嗎？有沒有想過這樣的自己會適合什麼樣的共居？如果對以上的問題感到好奇，快來填答這份心理測驗吧！",
    };
  },
  methods: {
    nextStep() {
      this.$emit("nextStep");
    },
    lastStep() {
      this.$emit("lastStep");
    },
  },
};
</script>
