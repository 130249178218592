<template>
  <div class="flex flex-col justify-between w-full h-full px-3 xl:flex-row">
    <!-- 網頁版左邊標題 -->
    <div class="hidden items-start justify-center w-1/2 h-full py-32 xl:flex">
      <div
        class="font-normal text-18 text-black text-left 2xl:ml-72 2xl:w-1/2 xl:ml-60 xl:w-3/5"
      >
        <div class="w-min">
          <div
            class="text-36 font-bold pt-20 pb-20 whitespace-nowrap"
            :class="
              surveyType === 'Colive' ? 'text-customGreen' : 'text-customOrange'
            "
          >
            {{ webTitle }}
          </div>
          <div class="xl:text-18">
            {{ webContent }}
          </div>
        </div>
      </div>
    </div>
    <!--內容-->
    <div class="xl:relative xl:w-1/2">
      <h1
        class="text-4xl font-black pt-8 pb-4 xl:hidden"
        :class="`text-${color}`"
      >
        Survey
      </h1>
      <div
        class="inline-block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 xl:w-1/3 xl:top-2/3 2xl:w-1/4"
      >
        <p
          class="whitespace-nowrap mb-6 text-lg xl:text-36 xl:absolute xl:-top-20 xl:left-1/2 xl:-translate-x-1/2"
        >
          共居會是接下來的生活選項嗎？
        </p>
        <div class="flex justify-between mb-8">
          <div
            class="w-24 py-1 px-2 cursor-pointer"
            :class="[
              answer
                ? `${bgClass} border-2 border-${color} text-white`
                : 'border-dotted border-2',
            ]"
            @click="answer = true"
          >
            <span class="text-xl">會</span>
            <br />
            <span class="text-sm">(或已經是)</span>
          </div>
          <div
            class="w-24 py-1 px-2 cursor-pointer"
            :class="[
              !answer
                ? `${bgClass} border-2 border-${color} text-white`
                : 'border-dotted border-2',
            ]"
            @click="answer = false"
          >
            <span class="text-xl">不會</span>
            <br />
            <span class="text-sm">(不想共居)</span>
          </div>
        </div>
        <button
          class="w-full rounded-full py-1 text-white font-bold cursor-pointer"
          :class="bgClass"
          @click="goNext"
          :disabled="isBtnDisable"
        >
          查看結果
        </button>
        <div
          class="w-full flex items-center pt-20 cursor-pointer"
          @click="goPrevious"
        >
          <img
            width="30px"
            :src="`/images/surveyCard/${
              surveyType === 'Colive' ? 'green' : 'orange'
            }Left.png`"
            alt=""
          />
          <div
            class="font-bold text-xl px-1 pt-1"
            :class="
              surveyType === 'Colive' ? 'text-customGreen' : 'text-customOrange'
            "
          >
            PREVIOUS
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { submit, getSurveyResult } from "@/api";
export default {
  name: "StepNine",
  data() {
    return {
      answer: true,
      webTitle: "你適合什麼樣的共居？",
      webContent:
        "好奇自己是什麼樣性格的人嗎？有沒有想過這樣的自己會適合什麼樣的共居？如果對以上的問題感到好奇，快來填答這份心理測驗吧！",
      isBtnDisable: false,
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep"]),
    ...mapGetters(["color", "imageColor", "bgClass"]),
  },
  methods: {
    async goNext() {
      this.isBtnDisable = true;
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "wanna_co_living",
          answer: this.answer,
        },
      ]);
      try {
        const submitRes = await submit(this.$store.state.surveyAnswer);
        const submitJson = await submitRes.json();
        // console.log(submitJson);
        this.$store.dispatch("setState", {
          key: "animal",
          value: submitJson.animal,
        });
        const res = await getSurveyResult({
          work: submitJson.work,
          age: submitJson.age,
        });
        const resJson = await res.json();
        this.$store.dispatch("setState", {
          key: "result",
          value: resJson,
        });
        this.$store.dispatch("setStepAction", this.currentStep + 1);
      } catch {
        this.isBtnDisable = false;
      }
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
