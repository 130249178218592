export const colivingStepList = [
  "EntryView",
  "StepOne",
  "StepTwo",
  "StepThree",
  "StepFour",
  "StepFive",
  "StepSix",
  "StepSeven",
  "StepEight",
  "StepNine",
  "StepTen",
  "StepThirteen",
  "StepFourteen",
  "AboutView",
];

export const sDGsList = [
  "EntryView",
  "StepZero",
  "StepOne",
  "StepTwo",
  "StepThree",
  "StepFour",
  "StepFive",
  "StepSix",
  "StepSeven",
  "StepEight",
  "StepNine",
  "StepTen",
  "StepEleven",
  "StepTwelve",
  "StepThirteen",
  "StepFourteen",
  "AboutView",
];
