<template>
  <SurveyCard
    :surveyType="surveyType"
    title="Survey"
    contentTitle="家的想像"
    describe="反映當代年輕世代的居住偏好，家的意象、互動方式、室友樣貌等"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <div class="flex flex-wrap items-center justify-center w-full px-4 pb-10">
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          4. 對你來說，家代表什麼意象?
        </div>
        <div
          class="w-1/2 px-2 pb-2"
          v-for="(item, index) in btnData"
          :key="index"
        >
          <DotBtn :dotValue="{ ...item, index }" @getValue="valChange" />
        </div>
      </div>
    </template>
  </SurveyCard>
</template>
<script>
import SurveyCard from "@/components/SurveyCard";
import DotBtn from "@/components/DotBtn";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepSix",
  components: {
    SurveyCard,
    DotBtn,
  },
  data() {
    return {
      btnData: [
        { value: "咖啡館", active: false },
        { value: "派對", active: false },
        { value: "樹洞", active: false },
        { value: "工作室", active: false },
        { value: "沙龍", active: false },
        { value: "健身房", active: false },
      ],
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    this.btnData.forEach((item) => {
      if (item.value === this.surveyAnswer.home_imagination) {
        item.active = true;
      }
    });
  },
  methods: {
    valChange(data) {
      this.btnData.forEach((item, index) => {
        item.active = index === data.index ? true : false;
      });
    },
    goNext() {
      if (!this.btnData.some((item) => item.active))
        return this.$store.dispatch("setErrorMessage", "fdsa");
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "home_imagination",
          answer: this.btnData.find((item) => item.active).value,
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
