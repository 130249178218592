<template>
  <div class="flex items-start justify-center text-left pl-4 text-xs mb-4">
    <div class="flex flex-col w-1/2">
      <span v-for="(items, index) in sdgArrOne" :key="'SDG_1' + index">
        <a href="javascript:void(0)" class="text-10 text-textGray xl:text-14">{{
          "SDG" + " " + Number(index + 1) + " " + items
        }}</a>
      </span>
    </div>
    <div class="flex flex-col w-1/2">
      <span v-for="(items, index) in sdgArrTwo" :key="'SDG_2' + index">
        <a href="javascript:void(0)" class="text-10 text-textGray xl:text-14">{{
          "SDG" + " " + Number(index + 10) + " " + items
        }}</a>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "SdgTag",
  data() {
    return {
      sdgArrOne: [
        "終結貧窮",
        "消除飢餓",
        "健康與福祉",
        "優質教育",
        "性別平權",
        "淨水及衛生",
        "可負擔的潔淨能源",
        "合適的工作及經濟成長",
        "工業化、創新及基礎建設",
      ],
      sdgArrTwo: [
        "減少不平等",
        "永續城鄉",
        "責任消費及生產",
        "氣候行動",
        "保育海洋生態",
        "保育陸域生態",
        "和平、正義及健全制度",
        "多元夥伴關係",
      ],
    };
  },
};
</script>
