export const shareLink = {
  green: {
    家貓: "https://survey.binnextgen.org?animal=greenCat",
    樹懶: "https://survey.binnextgen.org?animal=greenSloth",
    山羊: "https://survey.binnextgen.org?animal=greenGoat",
    孔雀: "https://survey.binnextgen.org?animal=greenPeacock",
  },
  orange: {
    家貓: "https://survey.binnextgen.org?animal=orangeCat",
    樹懶: "https://survey.binnextgen.org?animal=orangeSloth",
    山羊: "https://survey.binnextgen.org?animal=orangeGoat",
    孔雀: "https://survey.binnextgen.org?animal=orangePeacock",
  },
};

export const shareDescription = {
  家貓: "貓",
  樹懶: "樹懶",
  山羊: "山羊",
  孔雀: "孔雀",
};
