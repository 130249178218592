<template>
  <SurveyCard
    :surveyType="surveyType"
    contentTitle="客觀生活期待"
    describe="生活中很多事情需要用數字衡量，從租金、通勤距離、居住點位等等，請你/妳幫我們點下比較符合當下人生裡，理想中的生活期待值。"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <RangeSlider
        :rangeData="rangeData_one"
        v-model="rangeValue_one"
        :key="updateKey + 'rangeValue_one'"
      />
      <DotBtnGroup
        :key="updateKey + 'dotBtnValue'"
        :btnData="btnData"
        v-model="dotBtnValue"
        @input="getActiveBtn"
      />
      <RangeSlider
        :rangeData="rangeData_two"
        v-model="rangeValue_two"
        :key="updateKey + 'rangeValue_two'"
      />
    </template>
  </SurveyCard>
</template>

<script>
import SurveyCard from "@/components/SurveyCard";
import RangeSlider from "@/components/RangeSlider/Range_two";
import DotBtnGroup from "@/components/DotBtnGroup";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepTwo",
  components: {
    SurveyCard,
    RangeSlider,
    DotBtnGroup,
  },
  data() {
    return {
      rangeData_one: {
        title: "1. 願意負擔的每月租屋價格 (NTD)",
        data: [
          {
            value: "<6000",
          },
          {
            value: "9000",
          },
          {
            value: "12000",
          },
          {
            value: "15000",
          },
          {
            value: ">18000",
          },
        ],
        upLabel: ["<6000", "9000", "12000", "15000", ">18000"],
      },
      rangeData_two: {
        title: "3. 可接受的通勤時間？",
        data: [
          {
            value: "15",
          },
          {
            value: "30",
          },
          {
            value: "45",
          },
          {
            value: "60",
          },
          {
            value: "90",
          },
        ],
        upLabel: ["15min", "30min", "45min", "60min", "90min"],
      },
      btnData: [
        { value: "<10,000", active: false },
        { value: "10,000-20,000", active: false },
        { value: "20,000-30,000", active: false },
        { value: "30,000-40,000", active: false },
        { value: "40,000-50,000", active: false },
        { value: ">50,000", active: false },
      ],
      rangeValue_one: "<6000",
      rangeValue_two: "15",
      dotBtnValue: "",
      updateKey: 0,
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    this.rangeValue_one = this.surveyAnswer.budget;
    this.rangeValue_two = this.surveyAnswer.commuting_time;
    this.dotBtnValue = { value: this.surveyAnswer.living_expense };
    this.btnData.forEach((item) => {
      if (item.value === this.surveyAnswer.living_expense) {
        item.active = true;
      }
    });
    this.updateKey++;
  },
  methods: {
    goNext() {
      if (!this.dotBtnValue.value)
        return this.$store.dispatch("setErrorMessage", "fdsa");
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "budget",
          answer: this.rangeValue_one,
        },
        {
          keyValue: "living_expense",
          answer: this.dotBtnValue.value,
        },
        {
          keyValue: "commuting_time",
          answer: this.rangeValue_two,
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
    getActiveBtn(data) {
      this.btnData.forEach((item, index) => {
        index === data.index ? (item.active = true) : (item.active = false);
      });
    },
  },
};
</script>
