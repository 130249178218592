<template>
  <div class="flex flex-col w-full h-full xl:pt-20 xl:text-36 xl:px-10%">
    <div class="py-3 xl:text-left xl:font-bold" :class="`text-customOrange`">
      Action：除了共居外，我們對社會的行動
    </div>
    <div class="flex-1 xl:hidden">
      <swiper class="h-full" :options="swiperOption">
        <swiper-slide
          v-for="(item, index) in actionList"
          :key="index"
          class="slide"
        >
          <ActionCard :data="item" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
    </div>
    <div class="hidden xl:flex">
      <div
        class="w-1/4 text-sm"
        v-for="(item, index) in actionList"
        :key="index"
      >
        <ActionCard :data="item" />
      </div>
    </div>
    <div class="flex justify-between">
      <div class="z-50 p-8 xl:p-0 cursor-pointer" @click="goPrevious">
        <img class="w-28 xl:w-auto" :src="`/image/orangePrevious.png`" alt="" />
      </div>
      <div class="z-50 p-8 xl:p-0 cursor-pointer" @click="goNext">
        <img class="w-20 xl:w-auto" :src="`/image/orangeNext.png`" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import ActionCard from "@/components/ActionCard";
import { actionList } from "@/config/actionList";
import { mapState } from "vuex";

export default {
  name: "ActionCardView",
  components: {
    ActionCard,
  },
  data() {
    return {
      actionList,
      swiperOption: {
        speed: 1000,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom"></span>`;
          },
        },
        loop: true,
      },
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep"]),
  },

  methods: {
    goNext() {
      this.$store.dispatch("setStepAction", this.currentStep + 1);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>

<style lang="scss">
.bannerImg {
  background-color: black;
  box-shadow: 0px 2px 5px rgb(22, 22, 22);
}

.swiper-pagination-bullet-custom {
  &:hover {
    opacity: 1;
  }

  &.swiper-pagination-bullet-active {
    opacity: 1;
    background: rgba(#f85a3e, 0.8) !important;
  }
}
</style>
