var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-center w-full min-h-full xl:min-h-[850px]"},[(!_vm.stepZeroImg)?_c('div',{staticClass:"hidden items-start justify-center w-1/2 h-full py-32 xl:flex"},[_c('div',{staticClass:"font-normal text-18 text-black text-left ml-60 2xl:ml-72 2xl:w-1/2 xl:w-3/5"},[_c('div',{staticClass:"w-min"},[_c('div',{staticClass:"text-36 font-bold pt-20 pb-20 whitespace-nowrap",class:_vm.surveyType === 'Colive' ? 'text-customGreen' : 'text-customOrange'},[_vm._v(" "+_vm._s(_vm.webTitle)+" ")]),_c('div',{staticClass:"xl:text-18"},[_vm._v(" "+_vm._s(_vm.webContent)+" ")])])])]):_vm._e(),_c('div',{staticClass:"h-full w-full xl:w-1/2 xl:px-20"},[_c('div',{staticClass:"flex flex-col items-center justify-center relative w-full h-full px-4 pb-4"},[_c('div',{staticClass:"text-30 font-bold pb-4",class:[
          _vm.surveyType === 'Colive' ? 'text-customGreen' : 'text-customOrange',
          { 'xl:hidden': !_vm.stepZeroImg } ]},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"border rounded-xl min-h-3/4 flex flex-col xl:px-7 min-h-[500px] xl:min-h-[720px]",class:_vm.surveyType === 'Colive'
            ? 'border-customGreen'
            : 'border-customOrange'},[(!_vm.stepZeroImg)?_c('div',{staticClass:"hidden text-30 font-bold pt-6",class:[
            _vm.surveyType === 'Colive'
              ? 'text-customGreen'
              : 'text-customOrange',
            { 'xl:block': !_vm.stepZeroImg } ]},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"font-bold text-18 text-left pt-6 px-4",class:{ 'xl:text-36': _vm.stepZeroImg }},[_vm._v(" "+_vm._s(_vm.contentTitle)+" ")]),_c('div',{staticClass:"text-left text-12 py-2 px-4 mb-4 xl:text-18"},[_vm._v(" "+_vm._s(_vm.describe)+" ")]),_vm._t("default")],2),_c('div',{staticClass:"flex w-full px-4 py-2 items-end",class:_vm.hasPrevious ? 'justify-between' : 'justify-end'},[(_vm.hasPrevious)?_c('div',{staticClass:"w-36% ml-4 cursor-pointer",on:{"click":_vm.lastStep}},[_c('img',{attrs:{"src":("/images/surveyCard/" + (_vm.surveyType === 'Colive' ? 'green' : 'orange') + "Previous.png"),"alt":""}})]):_vm._e(),(_vm.hasNext)?_c('div',{staticClass:"w-1/4 mr-4 flex justify-end cursor-pointer",on:{"click":_vm.nextStep}},[_c('img',{staticClass:"items-end",attrs:{"src":("/images/surveyCard/" + (_vm.surveyType === 'Colive' ? 'green' : 'orange') + "Next.png"),"alt":""}})]):_vm._e()])])])]),(_vm.stepZeroImg)?_c('div',{staticClass:"hidden items-center w-1/2 h-full p-20 xl:flex"},[_c('img',{attrs:{"src":_vm.stepZeroImg,"alt":""}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }