export const submit = (data) =>
  fetch("https://www.sdgscolivingguide.org/api/survey", {
    body: JSON.stringify(data), // must match 'Content-Type' header

    credentials: "same-origin", // include, same-origin, *omit
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  });

export const getSurveyResult = ({ work, age }) =>
  fetch(
    `https://www.sdgscolivingguide.org/api/survey/statistics?work=${work}&age=${age}`
  );
