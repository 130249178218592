<template>
  <div class="p-10">
    <div class="dropdown inline-block relative">
      <button class=" ">
        <img class="w-8" src="/image/share.png" alt="" />
      </button>
      <ul
        class="dropdown-menu absolute hidden p-2 text-gray-700 rounded-md shadow-md bg-white"
      >
        <li class="p-2">
          <ShareNetwork
            network="facebook"
            :url="shareLink[imageColor][animal]"
            :title="`我是一隻${shareDescription[animal]}！那你呢？`"
          >
            Facebook
          </ShareNetwork>
        </li>
        <li class="p-2">
          <ShareNetwork
            network="line"
            :url="shareLink[imageColor][animal]"
            :title="`我是一隻${shareDescription[animal]}！那你呢？`"
          >
            Line
          </ShareNetwork>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { shareLink, shareDescription } from "@/config/shareLink";

export default {
  data() {
    return {
      shareLink,
      shareDescription,
    };
  },
  computed: {
    ...mapState(["animal"]),
    ...mapGetters(["imageColor"]),
  },
};
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
