<template>
  <div
    class="flex flex-col justify-between w-full h-full px-3 xl:flex-row xl:pt-36 xl:px-15%"
  >
    <div class="xl:flex-1 xl:flex xl:justify-center">
      <div class="xl:pr-28">
        <h1
          class="text-xl text-customOrange font-black pt-8 mb-4 xl:text-36 xl:font-bold xl:mb-16 xl:pt-0 xl:leading-10"
        >
          相信世代 青年住得起的家
        </h1>
        <div class="text-left mb-7 xl:text-lg">
          <p class="mb-7">
            相信世代協會長期協助北漂青年在大台北地區找到可以安身立命的場所，幫助租屋世代找到符合理想的房子、協助媒合志同道合的室友，甚至視情況提供一定金額的租屋補助。從台北的《龍江384》開始，至今協助將近20位青年找到適合居住的地方。
          </p>
          <p>
            我們與入住青年超越房東和房客的關係，形成緊密的社群網路。在青年空間入住的所有夥伴將自動加入「相信世代
            CONNECT」社群，這是一個秉持著「相信」而生的社群，放大對人生和世界的想像。
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col xl:flex-1">
      <img
        class="w-full cursor-pointer xl:mb-16"
        src="/image/action.png"
        alt=""
        srcset=""
      />
      <div class="flex justify-between py-4">
        <img
          @click="goPrevious"
          class="w-28 cursor-pointer sm:w-auto xl:w-1/2 2xl:w-auto"
          src="/image/orangePrevious.png"
          alt=""
        />
        <img
          @click="goNext"
          class="w-20 cursor-pointer sm:w-auto xl:w-1/3 2xl:w-auto"
          src="/image/orangeNext.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "BelieveGeneration",
  computed: {
    ...mapState(["surveyType", "currentStep"]),
    ...mapGetters(["color", "imageColor"]),
  },
  methods: {
    goNext() {
      this.$store.dispatch("setStepAction", this.currentStep + 1);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
