<template>
  <div class="flex flex-wrap items-stretch justify-center w-full px-4 pb-10">
    <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
      2. 個人每月平均生活開銷
    </div>
    <div class="w-1/3 px-2 pb-2" v-for="(items, index) in btnData" :key="index">
      <DotBtn :dotValue="{ ...items, index: index }" @getValue="valChange" />
    </div>
  </div>
</template>
<script>
import DotBtn from "@/components/DotBtn";
export default {
  name: "DotBtnGroup",
  props: ["value", "btnData"],
  components: {
    DotBtn,
  },
  data() {
    return {
      btnValue: this.value,
    };
  },
  watch: {
    btnValue() {
      this.$emit("input", this.btnValue);
    },
  },
  methods: {
    valChange(data) {
      this.btnValue = data;
    },
  },
};
</script>
