<template>
  <div class="w-full text-14 pt-2 px-4 text-left xl:text-18">
    <div class="pb-2 font-normal xl:text-18">
      {{ title }}
    </div>
    <div class="w-full">
      <input
        type="text"
        :placeholder="placeholder"
        class="w-full border-b border-black text-14 outline-none xl:text-18"
        v-model="questionAnswer"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "QuestionInput",
  props: {
    value: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      questionAnswer: this.value,
    };
  },
  watch: {
    questionAnswer: function () {
      this.$emit("input", this.questionAnswer);
    },
  },
};
</script>
