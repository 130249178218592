<template>
  <div class="com68 px-40 py-40">
    <div class="flex flex-wrap justify-center">
      <div class="w-1/3 flex flex-col justify-between">
        <!-- 標題部分 -->
        <div class="text-left">
          <p :class="['text-5xl', 'font-bold', `text-${color}`]">Result</p>
          <div class="py-20">測驗結果出來囉！來看看你是怎麼樣的人</div>
        </div>

        <div
          class="flex items-center justify-start pb-10 cursor-pointer"
          @click="goPrevious"
        >
          <img
            :src="`/image/${
              surveyType === 'Colive' ? 'green' : 'orange'
            }Previous.png`"
            alt=""
          />
        </div>
      </div>

      <div class="w-1/3">
        <!-- 中間動物圖片 -->
        <img :src="animal.image" alt="" />
      </div>

      <div class="w-1/3 xl:pt-10 2xl:pt-20">
        <!-- 右半邊部分 -->
        <div class="flex flex-col items-center">
          <div
            class="w-1/2 text-white text-4xl p-2 rounded-xl"
            :class="bgClass"
          >
            <span class="font-bold">{{ animal.name + " " }}</span>
          </div>

          <div class="flex items-center justify-center pt-10 pb-20 w-1/2">
            <img :src="animal.descriptionImage" alt="" />
          </div>

          <div class="flex items-center">
            <div class="flex items-center font-semibold text-24">
              <span class="text-customGreen">Co-Living</span
              ><span class="px-2 text-gray-400">x</span
              ><span class="text-customOrange">SDGs</span>
            </div>
            <ShareDropdown />
          </div>

          <div
            class="flex items-center justify-end py-10 ml-40 cursor-pointer"
            @click="goNext"
          >
            <img
              :src="`/image/${
                surveyType === 'Colive' ? 'green' : 'orange'
              }Next.png`"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ShareDropdown from "@/components/ShareDropdown.vue";

export default {
  name: "WebAnimalResult",
  components: {
    ShareDropdown,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["surveyType", "currentStep"]),
    ...mapGetters(["color", "imageColor", "animal", "bgClass"]),
  },
  methods: {
    goNext() {
      this.$store.dispatch("setStepAction", this.currentStep + 1);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
