<template>
  <SurveyCard
    :surveyType="surveyType"
    title="Survey"
    contentTitle="家的想像"
    describe="反映當代年輕世代的居住偏好，家的意象、互動方式、室友樣貌等"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <div class="flex flex-wrap items-center justify-center w-full px-4 pb-10">
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          3. 要搬家了，會想要帶哪兩樣東西到新住處呢？
        </div>
        <div
          class="w-1/2 px-2 pb-2"
          v-for="(item, index) in btnData"
          :key="index"
        >
          <DotBtn
            :dotValue="{ value: item, active: questionValue.includes(item) }"
            @getValue="valChange"
          />
        </div>
      </div>
    </template>
  </SurveyCard>
</template>
<script>
import SurveyCard from "@/components/SurveyCard";
import DotBtn from "@/components/DotBtn";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepFive",
  components: {
    SurveyCard,
    DotBtn,
  },
  data() {
    return {
      questionValue: [],
      btnData: [
        "合照",
        "廚具",
        "音樂/電影海報",
        "明信片",
        "咖啡機",
        "書和雜誌",
        "童年物品(ex. 玩偶)",
        "高蛋白",
        "外接螢幕",
        "植栽",
        "瑜珈墊",
        "人體工學椅",
      ],
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    const _this = this;
    ["living_item1", "living_item2"].forEach((item) => {
      if (_this.surveyAnswer[item].length > 0) {
        this.questionValue.push(_this.surveyAnswer[item]);
      }
    });
  },
  methods: {
    valChange(data) {
      if (this.questionValue.includes(data.value))
        return (this.questionValue = this.questionValue.filter(
          (item) => item !== data.value
        ));
      if (this.questionValue.length === 2) return;
      this.questionValue.push(data.value);
    },
    goNext() {
      if (this.questionValue.length < 2)
        return this.$store.dispatch("setErrorMessage", "fdsa");
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "living_item1",
          answer: this.questionValue[0],
        },
        {
          keyValue: "living_item2",
          answer: this.questionValue[1],
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
