<template>
  <div class="home w-full h-full">
    <component :is="stepList[currentStep]" />
  </div>
</template>
<script>
import EntryView from "@/views/EntryView";
import StepZero from "@/views/Step/StepZero";
import StepOne from "@/views/Step/StepOne";
import StepTwo from "@/views/Step/StepTwo";
import StepThree from "@/views/Step/StepThree";
import StepFour from "@/views/Step/StepFour";
import StepFive from "@/views/Step/StepFive";
import StepSix from "@/views/Step/StepSix";
import StepSeven from "@/views/Step/StepSeven";
import StepEight from "@/views/Step/StepEight";
import StepNine from "@/views/Step/StepNine";
import StepTen from "@/views/Step/StepTen";
import StepEleven from "@/views/Step/StepEleven";
import StepTwelve from "@/views/Step/StepTwelve";
import StepThirteen from "@/views/Step/StepThirteen";
import StepFourteen from "@/views/Step/StepFourteen";
import AboutView from "@/views/AboutView";
import { mapState, mapGetters } from "vuex";

export default {
  name: "HomeView",
  components: {
    EntryView,
    StepZero,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    StepSeven,
    StepEight,
    StepNine,
    StepTen,
    StepEleven,
    StepTwelve,
    StepThirteen,
    StepFourteen,
    AboutView,
  },
  computed: {
    ...mapState(["currentStep"]),
    ...mapGetters(["stepList"]),
  },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  methods: {
    resize() {
      let innerWidth = window.innerWidth;
      if (innerWidth >= 1280) {
        this.$store.dispatch("setDeviceAction", "web");
      } else {
        this.$store.dispatch("setDeviceAction", "phone");
      }
    },
  },
  watch: {
    currentStep() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
