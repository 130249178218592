export const orangeAnimalList = {
  家貓: {
    name: "貓 Cat",
    image: "/image/orange/cat.png",
    descriptionImage: "/image/orange/catDescription.png",
  },
  樹懶: {
    name: "樹懶 Sloth",
    image: "/image/orange/sloth.png",
    descriptionImage: "/image/orange/slothDescription.png",
  },
  山羊: {
    name: "羊 Goat",
    image: "/image/orange/goat.png",
    descriptionImage: "/image/orange/goatDescription.png",
  },
  孔雀: {
    name: "孔雀 Peacock",
    image: "/image/orange/peacock.png",
    descriptionImage: "/image/orange/peacockDescription.png",
  },
};

export const greenAnimalList = {
  家貓: {
    name: "貓 Cat",
    image: "/image/green/cat.png",
    descriptionImage: "/image/green/catDescription.png",
  },
  樹懶: {
    name: "樹懶 Sloth",
    image: "/image/green/sloth.png",
    descriptionImage: "/image/green/slothDescription.png",
  },
  山羊: {
    name: "羊 Goat",
    image: "/image/green/goat.png",
    descriptionImage: "/image/green/goatDescription.png",
  },
  孔雀: {
    name: "孔雀 Peacock",
    image: "/image/green/peacock.png",
    descriptionImage: "/image/green/peacockDescription.png",
  },
};
