<template>
  <div class="p-10 2xl:p-40">
    <div class="flex flex-wrap items-stretch justify-center">
      <div class="xl:w-1/3 lg:w-full">
        <!-- 標題部分 -->
        <div class="xl:flex xl:justify-center text-left py-10">
          <p :class="['text-5xl', 'font-bold', 'xl:w-4/5', titleClass]">
            Organization
          </p>
        </div>

        <div class="flex justify-center xl:h-4/5">
          <div
            :class="[
              'relative',
              'h-full',
              'border-2',
              'rounded-xl',
              'p-4',
              'xl:w-4/5',
              borderClass,
            ]"
          >
            <div class="flex justify-center pb-12">
              <img src="/image/believeGeneration.png" alt="" />
            </div>

            <div class="flex justify-center w-full">
              <div class="border border-textGray w-1/20"></div>
            </div>

            <p
              :class="[
                'text-2xl',
                'font-bold',
                'pb-10',
                'pt-4',
                'md:text-3xl',
                'xl:text-2xl',
                '2xl:text-3xl',
                titleClass,
              ]"
            >
              台灣相信世代發展協會
            </p>

            <div class="flex justify-center">
              <!-- <img src="/images/aboutUs-1.png" alt="" /> -->
              <p class="text-customGray text-left text-lg pb-20">
                秉持著秉持著相信而生的跨世代社群, 致力於推動台灣社會創新能量。
                成立以來,相信世代協會從永續發展、社會設計的角度關心青年探索、
                青年創作、青年居住三大領域, 協助青年創造對社會有正向意義的計畫,
                讓改變得以發生。
                若有興趣響應、參與、獲得任何與青年居住計畫相關的訊息,
                歡迎點擊下方連結！
              </p>
            </div>

            <a
              class="flex justify-end items-center text-18 font-semibold xs:absolute xs:bottom-2 xs:right-8"
              href="https://binnextgen.org/affordablehousing"
              target="blank"
            >
              <img src="/image/arrow.png" class="w-8 mr-1" alt="" />
              <span>相信世代</span>
            </a>
          </div>
        </div>
      </div>

      <div class="xl:w-1/3 xl:pb-0 lg:w-full lg:pb-10">
        <!-- 中間 -->
        <div class="text-left py-10">
          <p class="text-5xl font-bold opacity-0">none</p>
        </div>

        <div class="flex justify-center h-4/5">
          <div
            :class="[
              'relative',
              'h-full',
              'border-2',
              'rounded-xl',
              'p-4',
              'xl:w-4/5',
              borderClass,
            ]"
          >
            <div class="flex justify-center pb-5">
              <img src="/image/invisibleCity.png" alt="" />
            </div>

            <div class="flex justify-center w-full">
              <div class="border border-textGray w-1/20"></div>
            </div>

            <p
              :class="[
                'text-2xl',
                'font-bold',
                'pb-10',
                'pt-4',
                'md:text-3xl',
                'xl:text-2xl',
                '2xl:text-3xl',
                titleClass,
              ]"
            >
              IVC 在看得見的城市
            </p>

            <div class="flex justify-center">
              <!-- <img src="/images/aboutUs-1.png" alt="" /> -->
              <p class="text-customGray text-left text-lg pb-20">
                從前看不見的城市, 現在我們有機會看見了, 看見人們的情感、 活動、
                記憶。 從宏觀、 理性到微觀、 感性, 跨專業的探索城市,
                對熟悉又陌生的城市倡議。 社群夥伴橫跨都市設計、 景觀、
                建築及新聞、 城鄉等領域, 持續研究、
                發表以數據驅動創新決策的可能。
              </p>
            </div>
            <a
              class="flex justify-end items-center text-18 font-semibold xs:absolute xs:bottom-2 xs:right-8"
              href="https://www.facebook.com/ivcinvisiblecities"
              target="blank"
            >
              <img src="/image/arrow.png" class="w-8 mr-1" alt="" />
              <span>IVC 在看得見的城市</span>
            </a>
          </div>
        </div>
      </div>

      <div
        class="flex items-center justify-center w-full mt-10 xl:block xl:w-1/3 lg:mt-0 md:justify-between"
      >
        <!-- 右邊部分 -->
        <div class="w-full sm:w-auto">
          <div class="xl:flex xl:justify-center text-left py-10">
            <p :class="['text-5xl', 'font-bold', 'xl:w-4/5', titleClass]">
              Team
            </p>
          </div>

          <div class="flex justify-center">
            <div
              :class="[
                'border-2',
                'rounded-xl',
                'p-4',
                '2xl:w-4/5',
                'sm:w-auto',
                'w-full',
                borderClass,
              ]"
            >
              <div class="flex pb-2">
                <!-- <img src="/images/aboutUs-1.png" alt="" /> -->
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >數據研究
                </span>
                <span class="text-black text-left text-lg">
                  洪皓哲、 陳威竹
                </span>
              </div>

              <div class="flex pb-2">
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >設計討論
                </span>
                <span class="text-black text-left text-normal md:text-lg">
                  IVC團隊夥伴
                </span>
              </div>

              <div class="flex pb-2">
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >網站設計
                </span>
                <span class="text-black text-left text-normal md:text-lg">
                  洪皓哲、 陳威竹
                </span>
              </div>

              <div class="flex pb-2">
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >網站開發
                </span>
                <span class="text-black text-left text-normal md:text-lg">
                  Ken、 Jim、 Jason、 Ray
                </span>
              </div>

              <div class="flex pb-2">
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >問卷設計
                </span>
                <span class="text-black text-left text-normal md:text-lg">
                  吳昱明、 洪皓哲、 陳威竹
                </span>
              </div>

              <div class="flex pb-2">
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >美編插畫
                </span>
                <span class="text-black text-left text-normal md:text-lg">
                  LinCai
                </span>
              </div>

              <div class="flex pb-2">
                <span
                  class="text-black text-left font-bold text-normal md:text-lg pl-4 pr-6 whitespace-nowrap"
                  >素材提供
                </span>
                <span class="text-black text-left text-normal md:text-lg">
                  台灣相信世代發展協會
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-end justify-end h-full xl:h-auto">
          <div class="hidden w-full text-left sm:block">
            <div class="flex justify-center ml-4 sm:py-20" @click="goPrevious">
              <img
                class="w-2/3 xl:w-1/2"
                :src="`/image/${
                  surveyType === 'Colive' ? 'green' : 'orange'
                }Previous.png`"
                alt=""
              />
            </div>
            <p
              :class="[
                'text-6xl',
                'text-center',
                'font-bold',
                'px-4',
                'xl:text-7xl',
                titleClass,
              ]"
            >
              About Us
            </p>
          </div>
        </div>
      </div>
      <div class="sm:hidden block w-full pt-6">
        <div class="flex justify-start ml-4" @click="goPrevious">
          <img
            class=""
            :src="`/images/surveyCard/${
              surveyType === 'Colive' ? 'green' : 'orange'
            }Previous.png`"
            alt=""
          />
        </div>
      </div>
      <p class="py-8 text-customGray xl:absolute xl:bottom-10">2021 Produced</p>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "aboutUs",
  data() {
    return {};
  },
  computed: {
    ...mapState(["surveyType", "currentStep"]),
    ...mapGetters(["color", "imageColor"]),
    titleClass() {
      return `text-${this.color}`;
    },
    borderClass() {
      return `border-${this.color}`;
    },
  },
  methods: {
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
