<template>
  <Modal>
    <div
      class="bg-[#F2F2F2] px-8 xl:px-32 pt-12 xl:pt-28 pb-10 rounded-3xl border-4"
      :class="`${
        surveyType === 'Colive' ? 'border-customGreen' : 'border-customOrange'
      }`"
    >
      <div
        class="font-bold text-xl xl:text-6xl mb-6 xl:mb-12"
        :class="`${
          surveyType === 'Colive' ? 'text-customGreen' : 'text-customOrange'
        }`"
      >
        再稍等一下...
      </div>
      <div class="text-sm xl:text-24 mb-6 xl:mb-12">
        {{ errorContent }}
      </div>
      <button
        class="border-gray-400 py-2 px-9 border border-dotted text-sm xl:text-xl"
        @click="close"
      >
        繼續填答
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from "./Modal.vue";
import { mapState } from "vuex";

export default {
  components: {
    Modal,
  },
  methods: {
    close() {
      this.$store.dispatch("setErrorMessage", "");
      this.$store.dispatch(
        "setErrorContent",
        "有問題還沒回答唷！麻煩您先都填寫完再到下一步 :)"
      );
    },
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "errorContent"]),
  },
};
</script>
