import Vue from "vue";
import Vuex from "vuex";
import { colivingStepList, sDGsList } from "@/config/stepList";
import { orangeAnimalList, greenAnimalList } from "@/config/animalList";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    device: "web",
    surveyType: "Colive",
    currentStep: 0,
    animal: "家貓",
    result: {
      work: "",
      age: "",
      home_imagination: [],
      living_item: [],
    },
    surveyAnswer: {
      is_SDGs: false,
      sdgs_Q1: "",
      name: "",
      living_vibe: 1,
      interaction: 1,
      conflict: 1,
      budget: "<6000",
      living_expense: "",
      commuting_time: "15",
      home_like: "",
      living_district1: "",
      living_district2: "",
      living_district3: "",
      living_item1: "",
      living_item2: "",
      home_imagination: "",
      gender: "",
      work: "",
      rent_experience: "",
      email: "",
      age: "",
      salary: "",
      wanna_co_living: "",
    },
    errorMessage: "",
    errorContent: "有問題還沒回答唷！麻煩您先都填寫完再到下一步 :)",
  },
  getters: {
    color(state) {
      return state.surveyType === "Colive" ? "customGreen" : "customOrange";
    },
    bgClass(state) {
      return state.surveyType === "Colive"
        ? "bg-customGreen"
        : "bg-customOrange";
    },
    imageColor(state) {
      return state.surveyType === "Colive" ? "green" : "orange";
    },
    stepList(state) {
      return state.surveyType === "Colive" ? colivingStepList : sDGsList;
    },
    animal(state) {
      return state.surveyType === "Colive"
        ? greenAnimalList[state.animal ?? "家貓"]
        : orangeAnimalList[state.animal ?? "家貓"];
    },
    imagination(state) {
      return state.result.home_imagination.join("、");
    },
    item(state) {
      return state.result.living_item.join("、");
    },
  },
  mutations: {
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    SET_ERROR_CONTENT(state, errorContent) {
      state.errorContent = errorContent;
    },
    SET_DEVICE(state, items) {
      state.device = items;
    },
    SET_SURVEY_TYPE(state, items) {
      state.surveyType = items;
      state.surveyAnswer.is_SDGs = items !== "Colive";
    },
    SET_CURRENT_STEP(state, items) {
      state.currentStep = items;
    },
    SET_SURVEY_ANSWER(state, items) {
      items.forEach((innerItem) => {
        state.surveyAnswer[innerItem.keyValue] = innerItem.answer;
      });
      console.log(state.surveyAnswer);
    },
    SET_STATE(state, item) {
      state[item.key] = item.value;
      console.log(state);
    },
  },
  actions: {
    setDeviceAction(context, data) {
      context.commit("SET_DEVICE", data);
    },
    setStepAction(context, data) {
      context.commit("SET_CURRENT_STEP", data);
    },
    setAnswerAction(context, data) {
      context.commit("SET_SURVEY_ANSWER", data);
    },
    setSurveyTypeAction(context, data) {
      context.commit("SET_SURVEY_TYPE", data);
    },
    setState(context, data) {
      context.commit("SET_STATE", data);
    },
    setErrorMessage(context, data) {
      context.commit("SET_ERROR_MESSAGE", data);
    },
    setErrorContent(context, data) {
      context.commit("SET_ERROR_CONTENT", data);
    },
  },
  modules: {},
});
