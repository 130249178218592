<template>
  <SurveyCard
    :surveyType="surveyType"
    contentTitle="家的想像"
    describe="反映當代年輕世代的居住偏好，家的意象、互動方式、室友樣貌等"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <div class="flex flex-wrap items-center justify-center w-full px-4 pb-10">
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          2. 我最想住雙北市哪個區？（拖曳排序前三名）
        </div>
        <div class="flex w-full justify-between" :key="updateKey + 'dropArea'">
          <!-- bg-gray-200 mx-1 text-gray-500 -->
          <drop
            class="w-24 bg-gray-200 mx-1 text-gray-500 py-1 xl:text-18"
            @drop="onDropOne"
          >
            <div class="" v-if="!answerOne[0]">1</div>
            <span v-else>{{ answerOne[0] }}</span>
          </drop>
          <drop
            class="w-24 bg-gray-200 mx-1 text-gray-500 py-1 xl:text-18"
            @drop="onDropTwo"
          >
            <div class="" v-if="!answerTwo[0]">2</div>
            <span v-else>{{ answerTwo[0] }}</span>
          </drop>
          <drop
            class="w-24 bg-gray-200 mx-1 text-gray-500 py-1 xl:text-18"
            @drop="onDropThree"
          >
            <div class="" v-if="!answerThree[0]">3</div>
            <span v-else>{{ answerThree[0] }}</span>
          </drop>
        </div>
        <div class="border-t border-gray-400 w-full my-2 mx-1"></div>
        <transition-group
          name="list"
          tag="div"
          class="flex flex-wrap items-center justify-between w-full"
        >
          <drag
            v-for="n in numbers"
            :key="n"
            class="w-24 border-dotted border-textGray border py-1 mx-1 my-1 xl:text-18"
            :data="n"
            go-back
            >{{ n }}</drag
          >
        </transition-group>
      </div>
    </template>
  </SurveyCard>
</template>

<script>
import SurveyCard from "@/components/SurveyCard";
import { Drag, Drop } from "vue-easy-dnd";

import { mapState, mapGetters } from "vuex";

export default {
  name: "StepTwo",
  components: {
    SurveyCard,
    Drag,
    Drop,
  },

  data() {
    return {
      numbers: [
        "大安區",
        "大同區",
        "南港區",
        "萬華區",
        "中山區",
        "內湖區",
        "松山區",
        "信義區",
        "士林區",
        "中正區",
        "文山區",
        "北投區",
        "板橋區",
        "新莊區",
        "三重區",
        "蘆洲區",
        "中和區",
        "永和區",
        "土城區",
        "新店區",
        "淡水區",
      ],
      answerOne: [],
      answerTwo: [],
      answerThree: [],
      updateKey: 0,
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    ["One", "Two", "Three"].forEach((item, index) => {
      if (this.surveyAnswer["living_district" + (index + 1)].length > 0)
        this["answer" + item][0] =
          this.surveyAnswer["living_district" + (index + 1)];
    });
    this.updateKey++;
  },
  methods: {
    onDropOne(e) {
      this.answerOne = [e.data];
    },
    onDropTwo(e) {
      this.answerTwo = [e.data];
    },
    onDropThree(e) {
      this.answerThree = [e.data];
    },
    goNext() {
      if (this.checkAnswer())
        this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "living_district1",
          answer: this.answerOne[0],
        },
        {
          keyValue: "living_district2",
          answer: this.answerTwo[0],
        },
        {
          keyValue: "living_district3",
          answer: this.answerThree[0],
        },
      ]);
    },
    checkAnswer() {
      const one = this.answerOne;
      const two = this.answerTwo;
      const three = this.answerThree;
      let answerCorrect = true;
      if (one.length > 0 && two.length > 0 && three.length > 0) {
        if (one[0] === two[0] || one[0] === three[0] || two[0] === three[0]) {
          this.$store.dispatch("setErrorContent", "答案不可重複");
          this.$store.dispatch("setErrorMessage", "show");
          answerCorrect = false;
        }
      } else {
        this.$store.dispatch("setErrorMessage", "show");
        answerCorrect = false;
      }
      return answerCorrect;
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>

<style>
.main {
  margin: 20px 10px;
  border: 1px solid black;
  height: 200px;
  position: relative;
}

.drop-in {
  box-shadow: 0 0 5px grey;
}
</style>
