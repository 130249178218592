<template>
  <div class="xl:flex xl:items-start xl:h-full xl:pt-40">
    <!--  網頁版左邊標題 -->
    <div class="hidden w-1/8 xl:block"></div>
    <div class="hidden w-1/4 h-full text-left xl:block">
      <div class="font-bold text-4xl pt-6" :class="`text-${color}`">Result</div>
      <div class="py-4">測驗結果出來囉！來看看你是怎麼樣的人</div>
    </div>
    <div class="hidden w-1/4 xl:block"></div>
    <!--  右半邊長方形堆疊區塊 -->
    <div class="w-full pt-6 px-3 xl:w-1/3 2xl:w-1/4">
      <div class="font-bold text-4xl xl:hidden" :class="`text-${color}`">
        Result
      </div>
      <div class="py-4 xl:hidden">測驗結果出來囉！來看看你是怎麼樣的人</div>
      <!-- 第一行 -->
      <div class="flex items-center justify-center pb-6">
        <div
          class="border-2 py-1 px-4 w-full flex items-center"
          :class="`border-${color}`"
        >
          <img class="pr-8" src="/image/deTriangle.png" alt="" />
          <span class="font-bold text-2xl">{{ result.work }}</span>
        </div>
      </div>

      <!-- 第二行 -->
      <div class="flex items-center justify-center pb-3">
        <div
          class="border-2 p-6 w-full flex items-center"
          :class="`border-${color}`"
        >
          <img class="pr-4" :src="`/image/${imageColor}Square.png`" alt="" />

          <div class="flex flex-wrap items-center pl-1">
            <span class="md:text-normal text-xs pr-6"
              >多數人喜歡, 居住氛圍傾向</span
            >
            <span class="md:text-xl text-normal font-bold">安靜相處</span>
          </div>
        </div>
      </div>

      <!-- 第三行 -->
      <div class="flex items-center justify-center pb-3">
        <div
          class="border-2 p-6 pr-0 w-full flex items-center xs:pr-6"
          :class="`border-${color}`"
        >
          <img class="pr-4" :src="`/image/${imageColor}Triangle.png`" alt="" />
          <div>
            <p class="md:text-normal text-xs pl-1 xs:pr-4">
              {{ result.age }} 歲的
              <span class="md:text-xl text-sm font-bold">{{ result.work }}</span
              >, 願意負擔的
            </p>
            <p class="md:text-normal text-xs pl-1 pr-6 text-left">
              月租金大約落在
              <span class="md:text-xl text-sm font-bold">
                ${{ result.budget }}元</span
              >
            </p>
          </div>
        </div>
      </div>

      <!-- 第四行 -->
      <div class="flex items-center justify-center pb-3">
        <div
          class="border-2 p-6 w-full flex items-center"
          :class="`border-${color}`"
        >
          <img class="pr-4" :src="`/image/${imageColor}Circle.png`" alt="" />
          <div>
            <p class="md:text-normal text-xs pl-1 pr-6 text-left">
              家的意象排行前三名是
            </p>

            <p class="text-left text-normal font-bold md:text-xl">
              {{ imagination }}
            </p>
          </div>
        </div>
      </div>

      <!-- 第五行 -->
      <div class="flex items-center justify-center pb-3">
        <div
          class="border-2 p-6 w-full flex items-center"
          :class="`border-${color}`"
        >
          <img
            class="my-4 rotate-90"
            :src="`/image/${imageColor}Square.png`"
            alt=""
          />

          <div class="ml-4">
            <p class="md:text-normal text-xs pl-1 pr-6 text-left">
              最想帶到新家的物件前二名是
            </p>

            <p class="text-left text-normal font-bold md:text-lg">
              {{ item }}
            </p>
          </div>
        </div>
      </div>
      <!-- NEXT圖片按鈕 -->
      <div
        class="flex items-center justify-end py-10 cursor-pointer"
        @click="goNext"
      >
        <img
          class="w-20 xl:w-auto"
          :src="`/image/${imageColor}Next.png`"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ResultView",
  computed: {
    ...mapState(["surveyType", "currentStep", "result"]),
    ...mapGetters(["color", "imageColor", "imagination", "item"]),
  },
  methods: {
    goNext() {
      this.$store.dispatch("setStepAction", this.currentStep + 1);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
