export const actionList = [
  {
    name: "01 屬於你的板橋繪本",
    description:
      "入住府中106的三位夥伴，一位喜歡插畫、一位喜歡在城市裡散步、一位喜歡吃美食，不約而同的，他們都是來自外地的青年，落腳在板橋這座城市，希望透過外來者的眼光，記錄、創造、連結板橋關係人口的記憶。本次計畫將透過插畫與繪本製作，記錄一系列板橋景象，並且留下揮灑空間，邀請得到繪本的夥伴共創板橋記憶。",
    subTitle: ["Target SDG 11 永續城鄉"],
    image: "/image/action01.png",
  },
  {
    name: "02 沉浸式工作坊",
    description:
      "住在龍江384的幾位夥伴，具有教育相關背景的他們，恰好都對心理健康議題非常關心，對青年們而言，住在龍江384日子就像擁有了新的家人，家裡的餐桌就成了每日進行深度交流日常經驗的場所。在他們各自教育、心理諮商的日常場域中，觀察到台灣有越來越多情緒勒索的情境在日常生活中發生，出自於一個想透過自身專業促進心理健康的心意，決定攜手策劃一場情緒勒索為基底的沈浸式心靈療癒工作坊，以「消失的室友」為起點，透過解謎的方式，一步一步深入挖掘日常生活的情緒陰影，並且從中解放。",
    subTitle: ["Target SDG 3.4 促進心理健康、", "SDG 4.7 優質永續教育"],
    image: "/image/action02.png",
  },
  {
    name: "03 浮洲環境劇場",
    description:
      "相信世代第五個青年共居空間台藝174，位在板橋浮洲，就在台灣藝術大學不遠處。浮州相對於板橋市中心，是一個較沒有存在感的地方，於是台藝174的表演藝術夥伴，決定策劃一系列《浮洲環境劇場》活動，透過戲劇走讀，從浮州火車站出發，踏上一場充滿驚奇的藝術表演，從戲劇之中，重新認識不熟悉的浮洲地區。",
    subTitle: ["Target SDG 11.3 提升具包容性、", "永續的都市化和參與程度"],
    image: "/image/action03.png",
  },
  {
    name: "04 古道生態攝影計畫",
    description:
      "你對大自然有多少認識呢？對於歷史上曾在台灣各地遍佈的古道有多少了解呢？合江58的其中一位入住青年，是一名高山嚮導，同時也是一名職業攝影師。深感於大台北地區許多古道漸漸被遺忘在人們的記憶中，同時加上這兩年走進大自然的熱潮，卻不是人人都知道與自然正確應對的方式，決定推出古道生態攝影計畫，結合低碳旅行、古道歷史、攝影教學，將大台北地區的古道，重新呈現在眾人面前。",
    subTitle: ["Target SDG 15 永續陸域生態"],
    image: "/image/action04.png",
  },
];
