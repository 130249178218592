<template>
  <div class="flex justify-center items-center w-full h-full">
    <div class="hidden xl:block xl:w-1/8"></div>
    <div
      class="hidden relative flex-col items-start h-full pt-40 xl:flex xl:w-1/4"
    >
      <div class="font-bold text-4xl text-left py-6" :class="`text-${color}`">
        Result
      </div>
      <p class="text-left">
        除了客觀質性的資料，我們還幫你做了人格特質分析，快來看看你是哪種動物吧！
      </p>

      <div
        class="absolute bottom-0 left-0 -translate-y-1/2 z-50 cursor-pointer"
        @click="goPrevious"
      >
        <img
          class="w-20 xl:w-auto"
          :src="`/image/${imageColor}Previous.png`"
          alt=""
        />
      </div>
    </div>
    <div class="hidden xl:block xl:w-1/4"></div>
    <div class="xl:w-1/4 xl:relative h-full">
      <div
        class="absolute w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      >
        <h1
          class="text-5xl font-bold pb-8 xl:text-96 xl:text-right"
          :class="`text-${color}`"
        >
          Persona
        </h1>
        <p class="px-16 xl:hidden">
          除了客觀質性的資料，我們還幫你做了人格特質分析，快來看看你是哪種動物吧！
        </p>
      </div>
      <div
        class="absolute bottom-0 right-0 -translate-y-1/2 -translate-x-1/2 z-50 xl:translate-x-0 cursor-pointer"
        @click="goNext"
      >
        <img
          class="w-20 xl:w-auto"
          :src="`/image/${imageColor}Next.png`"
          alt=""
        />
      </div>

      <div
        class="absolute bottom-0 left-0 -translate-y-1/2 translate-x-1/2 z-50 cursor-pointer xl:hidden"
        @click="goPrevious"
      >
        <img
          class="w-28 xl:w-auto"
          :src="`/image/${imageColor}Previous.png`"
          alt=""
        />
      </div>
    </div>
    <div class="hidden xl:block xl:w-1/8"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepThirteen",
  computed: {
    ...mapState(["surveyType", "currentStep"]),
    ...mapGetters(["color", "imageColor"]),
  },
  methods: {
    goNext() {
      this.$store.dispatch("setStepAction", this.currentStep + 1);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
