import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/main.css";
import "swiper/css/swiper.css";

import VueAwesomeSwiper from "vue-awesome-swiper";
import VueSocialSharing from "vue-social-sharing";

Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(VueSocialSharing);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
