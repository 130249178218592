<template>
  <div class="flex items-center justify-center w-full px-4 pb-10">
    <div class="w-full">
      <div class="font-bold text-14 text-left py-2 xl:text-18">
        {{ rangeData.title }}
      </div>
      <div class="flex w-full">
        <div class="w-1/5 text-left text-12 xl:text-14">
          {{ rangeData.upLabel[0] }}
        </div>
        <div class="relative w-1/5 text-center text-12 xl:text-14">
          <span class="absolute left-1/4 -translate-x-1/2">{{
            rangeData.upLabel[1]
          }}</span>
        </div>
        <div class="w-1/5 text-center text-12 xl:text-14">
          {{ rangeData.upLabel[2] }}
        </div>
        <div class="relative w-1/5 text-center text-12 xl:text-14">
          <span class="absolute left-3/4 -translate-x-1/2">{{
            rangeData.upLabel[3]
          }}</span>
        </div>
        <div class="w-1/5 text-right text-12 xl:text-14">
          {{ rangeData.upLabel[4] }}
        </div>
      </div>
      <VueSlider
        v-model="sliderValue"
        :data="rangeData.data"
        :data-value="'value'"
        :tooltip="'none'"
      />
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  name: "Range_two",
  props: ["value", "rangeData"],
  components: {
    VueSlider,
  },
  data() {
    return {
      sliderValue: this.value,
    };
  },
  watch: {
    sliderValue() {
      this.$emit("input", this.sliderValue);
    },
  },
};
</script>
<style>
.vue-slider-process {
  background-color: black;
}
.vue-slider-dot-handle-focus {
  box-shadow: 0px 0px 1px 2px rgb(0 0 0 / 36%);
}
.vue-slider-dot-tooltip-inner {
  border-color: black;
  background: black;
}

.vue-slider-mark-label {
  cursor: pointer;
}

.vue-slider-mark-step {
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
</style>
