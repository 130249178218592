<template>
  <SurveyCard
    :surveyType="surveyType"
    title="Survey"
    contentTitle="家的想像"
    describe="反映當代年輕世代的居住偏好，家的意象、互動方式、室友樣貌等"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <QuestionInput
        :key="updateKey + 'homeLike'"
        title="1. 你/妳想像中的家，長什麼樣子呢？"
        placeholder="ex.有著懶骨頭與木地板的小公寓"
        v-model="questionValue"
      />
    </template>
  </SurveyCard>
</template>
<script>
import SurveyCard from "@/components/SurveyCard";
import QuestionInput from "@/components/QuestionInput";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepThree",
  components: {
    SurveyCard,
    QuestionInput,
  },
  data() {
    return {
      questionValue: "",
      updateKey: 0,
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    this.questionValue = this.surveyAnswer.home_like;
    this.updateKey++;
  },
  methods: {
    goNext() {
      if (!this.questionValue)
        return this.$store.dispatch("setErrorMessage", "fdsa");
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "home_like",
          answer: this.questionValue,
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
