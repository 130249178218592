<template>
  <SurveyCard
    :surveyType="surveyType"
    title="Survey"
    contentTitle="基本資料"
    describe="讓我們更了解你的一些小小問題"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <div class="flex flex-wrap items-center justify-center w-full px-4 pb-10">
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          1. 生理性別
        </div>
        <div
          class="w-1/2 px-2 pb-2"
          v-for="(item, index) in btnData[0]"
          :key="index"
        >
          <DotBtn
            :dotValue="{ ...item, index }"
            @getValue="handleQuestionOne"
          />
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-center w-full px-4 pb-10">
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          2. 工作型態
        </div>
        <div
          class="w-1/2 px-2 pb-2"
          v-for="(item, index) in btnData[1]"
          :key="index"
        >
          <DotBtn
            :dotValue="{ ...item, index }"
            @getValue="handleQuestionTwo"
          />
        </div>
      </div>
      <div class="flex flex-wrap items-center justify-start w-full px-4 pb-10">
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          3. 有多久在外住宿經驗？
        </div>
        <div
          class="w-1/3 px-2 pb-2"
          v-for="(item, index) in btnData[2]"
          :key="index"
        >
          <DotBtn
            :dotValue="{ ...item, index }"
            @getValue="handleQuestionThree"
          />
        </div>
      </div>
    </template>
  </SurveyCard>
</template>
<script>
import SurveyCard from "@/components/SurveyCard";
import DotBtn from "@/components/DotBtn";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepSeven",
  components: {
    SurveyCard,
    DotBtn,
  },
  data() {
    return {
      btnData: [
        [
          { value: "生理男性", active: false },
          { value: "生理女性", active: false },
        ],
        [
          { value: "學生族群", active: false },
          { value: "上班族", active: false },
          { value: "自由工作者", active: false },
          { value: "打工族", active: false },
        ],
        [
          { value: "不滿 1 年", active: false },
          { value: "1-3 年", active: false },
          { value: "4-6 年", active: false },
          { value: "7-9 年", active: false },
          { value: "10 年以上", active: false },
        ],
      ],
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    ["gender", "work", "rent_experience"].forEach((outerItem, outerIndex) => {
      this.btnData[outerIndex].forEach((innerItem) => {
        if (innerItem.value === this.surveyAnswer[outerItem]) {
          innerItem.active = true;
        }
      });
    });
  },
  methods: {
    handleQuestionOne(data) {
      this.btnData[0].forEach((item, index) => {
        item.active = index === data.index ? true : false;
      });
    },
    handleQuestionTwo(data) {
      this.btnData[1].forEach((item, index) => {
        item.active = index === data.index ? true : false;
      });
    },
    handleQuestionThree(data) {
      this.btnData[2].forEach((item, index) => {
        item.active = index === data.index ? true : false;
      });
    },
    goNext() {
      if (
        !(
          this.btnData[0].some((item) => item.active) &&
          this.btnData[1].some((item) => item.active) &&
          this.btnData[2].some((item) => item.active)
        )
      )
        return this.$store.dispatch("setErrorMessage", "fdsa");
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "gender",
          answer: this.btnData[0].find((item) => item.active).value,
        },
        {
          keyValue: "work",
          answer: this.btnData[1].find((item) => item.active).value,
        },
        {
          keyValue: "rent_experience",
          answer: this.btnData[2].find((item) => item.active).value,
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
