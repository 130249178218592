<template>
  <div
    class="fixed w-full h-full bg-black/[.2] flex justify-center items-center z-50"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "ModalBox",
};
</script>
