<template>
  <SurveyCard
    :surveyType="surveyType"
    contentTitle="居住性格"
    describe="關於生活中大大小小的選擇，人際互動、公私領域、居住氛圍等等，希望你/妳能從兩個端點的敘述中，依照認同程度給一個落點。"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <RangeSlider
        :rangeData="rangeData_one"
        v-model="rangeValue_one"
        :key="updateKey + 'rangeValue_one'"
      />
      <RangeSlider
        :rangeData="rangeData_two"
        v-model="rangeValue_two"
        :key="updateKey + 'rangeValue_two'"
      />
      <RangeSlider
        :rangeData="rangeData_three"
        v-model="rangeValue_three"
        :key="updateKey + 'rangeValue_three'"
      />
    </template>
  </SurveyCard>
</template>

<script>
import SurveyCard from "@/components/SurveyCard";
import RangeSlider from "@/components/RangeSlider/Range_one";
import { mapState } from "vuex";

export default {
  name: "StepOne",
  components: {
    SurveyCard,
    RangeSlider,
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
  },
  data() {
    return {
      rangeData_one: {
        title: "1. 比較喜歡什麼樣的居住氛圍呢？",
        data: [
          {
            value: 1,
            name: "1",
          },
          {
            value: 2,
            name: "",
          },
          {
            value: 3,
            name: "",
          },
          {
            value: 4,
            name: "",
          },
          {
            value: 5,
            name: "5",
          },
        ],
        upLabel: ["靜態陪伴", "", "熱鬧開趴"],
      },
      rangeData_two: {
        title: "2. 期待跟室友的互動關係是？",
        data: [
          {
            value: 1,
            name: "1",
          },
          {
            value: 2,
            name: "",
          },
          {
            value: 3,
            name: "3",
          },
          {
            value: 4,
            name: "",
          },
          {
            value: 5,
            name: "5",
          },
        ],
        upLabel: ["分擔生活成本", "陪伴與交談", "摯友"],
      },
      rangeData_three: {
        title: "3. 衝突發生時，會怎麼溝通？",
        data: [
          {
            value: 1,
            name: "1",
          },
          {
            value: 2,
            name: "",
          },
          {
            value: 3,
            name: "",
          },
          {
            value: 4,
            name: "",
          },
          {
            value: 5,
            name: "5",
          },
        ],
        upLabel: ["訊息溝通", "", "直接溝通"],
      },
      rangeValue_one: "",
      rangeValue_two: "",
      rangeValue_three: "",
      updateKey: 0,
    };
  },
  mounted() {
    this.rangeValue_one = this.surveyAnswer.living_vibe;
    this.rangeValue_two = this.surveyAnswer.interaction;
    this.rangeValue_three = this.surveyAnswer.conflict;
    this.updateKey++;
  },
  methods: {
    goNext() {
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "living_vibe",
          answer: this.rangeValue_one,
        },
        {
          keyValue: "interaction",
          answer: this.rangeValue_two,
        },
        {
          keyValue: "conflict",
          answer: this.rangeValue_three,
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
