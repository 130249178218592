<template>
  <div
    class="flex items-center justify-center h-full border text-14 py-1 cursor-pointer xl:text-18"
    @click="getValue"
    :class="
      dotValue.active
        ? 'border-solid border-black bg-black text-white'
        : 'border-dotted border-textGray'
    "
  >
    <span>{{ dotValue.value }}</span>
  </div>
</template>
<script>
export default {
  name: "DotBtn",
  props: ["dotValue"],
  data() {
    return {};
  },
  computed: {
    // activeClass() {
    //   return () => {
    //     if (this.$store.state.surveyType === "Colive") {
    //       return "border-customOrange";
    //     } else if (this.$store.state.surveyType === "SDGs") {
    //       return "border-customGreen";
    //     }
    //   };
    // },
  },
  methods: {
    getValue() {
      this.$emit("getValue", this.dotValue);
    },
  },
};
</script>
