<template>
  <SurveyCard
    :surveyType="surveyType"
    title="Survey"
    contentTitle="基本資料"
    describe="讓我們更了解你的一些小小問題"
    @nextStep="goNext"
    @lastStep="goPrevious"
  >
    <template>
      <QuestionInput
        title="4. 信箱"
        v-model="questionOne"
        :key="updateKey + 'email'"
      />
      <div class="flex flex-wrap items-center justify-center w-full px-4 py-10">
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          5. 年齡
        </div>
        <div
          class="w-1/2 px-2 pb-2"
          v-for="(item, index) in btnData[0]"
          :key="index"
        >
          <DotBtn
            :dotValue="{ ...item, index }"
            @getValue="handleQuestionTwo"
          />
        </div>
      </div>
      <div
        class="flex flex-wrap items-stretch justify-center w-full px-4 pb-10"
      >
        <div class="pb-2 text-14 text-left w-full font-bold xl:text-18">
          6. 月薪資
        </div>
        <div
          class="w-1/3 px-2 pb-2"
          v-for="(item, index) in btnData[1]"
          :key="index"
        >
          <DotBtn
            :dotValue="{ ...item, index }"
            @getValue="handleQuestionThree"
          />
        </div>
      </div>
    </template>
  </SurveyCard>
</template>
<script>
import SurveyCard from "@/components/SurveyCard";
import DotBtn from "@/components/DotBtn";
import QuestionInput from "@/components/QuestionInput";
import { mapState, mapGetters } from "vuex";

export default {
  name: "StepEight",
  components: {
    SurveyCard,
    DotBtn,
    QuestionInput,
  },
  data() {
    return {
      questionOne: "",
      btnData: [
        [
          { value: "<25", active: false },
          { value: "25-29", active: false },
          { value: "30-39", active: false },
          { value: "40-49", active: false },
          { value: "50-59", active: false },
          { value: ">59", active: false },
        ],
        [
          { value: "<22,000", active: false },
          { value: "22,000-30,000", active: false },
          { value: "30,000-40,000", active: false },
          { value: "40,000-50,000", active: false },
          { value: "50,000-60,000", active: false },
          { value: "60,000-70,000", active: false },
          { value: "70,000-80,000", active: false },
          { value: "80,000-90,000", active: false },
          { value: ">90,000", active: false },
        ],
      ],
      updateKey: 0,
    };
  },
  computed: {
    ...mapState(["surveyType", "currentStep", "surveyAnswer"]),
    ...mapGetters(["color", "imageColor"]),
  },
  mounted() {
    this.questionOne = this.surveyAnswer.email;
    ["age", "salary"].forEach((outerItem, outerIndex) => {
      this.btnData[outerIndex].forEach((innerItem) => {
        if (innerItem.value === this.surveyAnswer[outerItem]) {
          innerItem.active = true;
        }
      });
    });
    this.updateKey++;
  },
  methods: {
    handleQuestionTwo(data) {
      this.btnData[0].forEach((item, index) => {
        item.active = index === data.index ? true : false;
      });
    },
    handleQuestionThree(data) {
      this.btnData[1].forEach((item, index) => {
        item.active = index === data.index ? true : false;
      });
    },
    validateEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    },
    goNext() {
      if (
        !(
          this.validateEmail(this.questionOne) &&
          this.btnData[0].some((item) => item.active) &&
          this.btnData[1].some((item) => item.active)
        )
      )
        return this.$store.dispatch("setErrorMessage", "fdsa");
      this.$store.dispatch("setStepAction", this.currentStep + 1);
      this.$store.dispatch("setAnswerAction", [
        {
          keyValue: "email",
          answer: this.questionOne,
        },
        {
          keyValue: "age",
          answer: this.btnData[0].find((item) => item.active).value,
        },
        {
          keyValue: "salary",
          answer: this.btnData[1].find((item) => item.active).value,
        },
      ]);
    },
    goPrevious() {
      this.$store.dispatch("setStepAction", this.currentStep - 1);
    },
  },
};
</script>
