<template>
  <div class="w-full">
    <component :is="showList[device]" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import WebAnimal from "@/views/WebAnimalResult";
import PhoneAnimal from "@/views/PhoneAnimalResult";
export default {
  name: "AnimalResult",
  components: { WebAnimal, PhoneAnimal },
  data() {
    return {
      showList: {
        web: "WebAnimal",
        phone: "PhoneAnimal",
      },
    };
  },
  computed: {
    ...mapState(["device"]),
  },
  methods: {},
};
</script>
